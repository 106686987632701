import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import loadable from '@loadable/component';
import { ValidationError } from '../../components';

// Dynamically load LocationAutocompleteInputImpl
const LocationAutocompleteInputImpl = loadable(() =>
  import(/* webpackChunkName: "LocationAutocompleteInputImpl" */ './LocationAutocompleteInputImpl')
);

class LocationAutocompleteInputComponent extends Component {
  componentDidMount() {
    // Ensure that when the component mounts, the initial value is properly set.
    const { input, initialValue } = this.props;
    if (initialValue && !input.value) {
      input.onChange(initialValue); // Update the form's input value with initialValue
    }
  }
  componentDidUpdate(prevProps) {
    const { input, initialValue } = this.props;
    if (initialValue !== prevProps.initialValue && input.value !== initialValue) {
      input.onChange(initialValue); // Update the form's input value with initialValue
    }
  }

  render() {
    const {
      rootClassName,
      labelClassName,
      hideErrorMessage,
      initialValue,
      ...restProps
    } = this.props;
    const { input, label, meta, valueFromForm, ...otherProps } = restProps;

    const value =
      typeof valueFromForm !== 'undefined' && input.value === '' ? valueFromForm : input.value;

    const locationAutocompleteProps = { label, meta, ...otherProps, input: { ...input, value } };

    const labelInfo = label ? (
      <label className={labelClassName} htmlFor={input.name}>
        {label}
      </label>
    ) : null;

    return (
      <div className={rootClassName}>
        {labelInfo}
        <LocationAutocompleteInputImpl {...locationAutocompleteProps} />
        {hideErrorMessage ? null : <ValidationError fieldMeta={meta} />}
      </div>
    );
  }
}

LocationAutocompleteInputComponent.defaultProps = {
  rootClassName: null,
  labelClassName: null,
  type: null,
  label: null,
  initialValue: '',
};

LocationAutocompleteInputComponent.propTypes = {
  rootClassName: string,
  labelClassName: string,
  input: shape({
    onChange: func.isRequired,
    name: string.isRequired,
  }).isRequired,
  label: string,
  meta: object.isRequired,
  initialValue: string, // Add initialValue prop type
};

// Export the correct component here
export default LocationAutocompleteInputComponent;

export const FieldLocationAutocompleteInput = props => {
  return <Field component={LocationAutocompleteInputComponent} {...props} />;
};
